import React from "react"
import Article from "../components/Article"
import i18nHOC from "../components/i18nHOC"
import Paragraph from "../components/Paragraph"
import ImageWithTitle from "../components/ImageWithTitle"
import { graphql } from "gatsby"
import ImageGrid from "../components/ImageGrid"
import SinglePortraitImageWithTitle from "../components/SinglePortraitImageWithTitle"

function arugulaAndWintercress({ data, t }) {
  return <Article coverImage={{ image: data.coverImage, title: t("items.arugulaAndWintercress.coverCap")}}
                  title={t("items.arugulaAndWintercress.title")}>
    <Paragraph>{t("items.arugulaAndWintercress.p1")}</Paragraph>
    <Paragraph>{t("items.arugulaAndWintercress.p2")}</Paragraph>
    <SinglePortraitImageWithTitle fluid={data.arugulaAndWintercress1.childImageSharp.fluid} title={t("items.arugulaAndWintercress.cap1")} />
    <ImageWithTitle fluid={data.arugulaAndWintercress2.childImageSharp.fluid} title={t("items.arugulaAndWintercress.cap2")}/>
    <ImageGrid>
      <ImageWithTitle fluid={data.grid1_1.childImageSharp.fluid} title={t("items.arugulaAndWintercress.cap3")}/>
      <ImageWithTitle fluid={data.grid1_2.childImageSharp.fluid} title={t("items.arugulaAndWintercress.cap4")}/>
    </ImageGrid>
    <ImageWithTitle fluid={data.arugulaAndWintercress3.childImageSharp.fluid} title={t("items.arugulaAndWintercress.cap5")}/>
  </Article>
}

export default i18nHOC(arugulaAndWintercress);

export const query = graphql`
  query {
    coverImage: file(relativePath: { eq: "arugula-and-wintercress/IMG_9846.jpg" }) {
        ...CoverImage
    },
    arugulaAndWintercress1: file(relativePath: { eq: "arugula-and-wintercress/DSC_2540.JPG" }) {
      ...FluidArticleImage
    },
    arugulaAndWintercress2: file(relativePath: { eq: "arugula-and-wintercress/DSC_2527.JPG" }) {
      ...FluidArticleImage
    },
    grid1_1: file(relativePath: { eq: "arugula-and-wintercress/arugula on machavariani.JPG" }) {
      ...FluidArticleImage
    },
    grid1_2: file(relativePath: { eq: "arugula-and-wintercress/bittercress on tamarashvili.JPG" }) {
      ...FluidArticleImage
    },
    arugulaAndWintercress3: file(relativePath: { eq: "arugula-and-wintercress/DSC_9727.JPG" }) {
      ...FluidArticleImage
    },
  }
`